// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var BtnPlay = "bd_db";
export var icon = "bd_s";
export var circle = "bd_dc";
export var triangle = "bd_dd";
export var text = "bd_bJ";
export var aniSpin = "bd_c";